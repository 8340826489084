/* BEGIN: Additional Styles */
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

#login {
  padding-right: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* align-content: center; */
  /* text-align: center; */
  max-width: 600px;
}

#login-bg {
  background-image: url('../assets/brand/login-bg.jpg');
  min-height: 510px;
  padding: 0px;
  background-size: cover;
  background-repeat: none;
  overflow: hidden;
}

#blue-logo {
  max-width: 150px;
  margin: auto;
  padding-bottom: 2rem;
}

.engie-logo {
  text-align: center;
}

#wide-button {
  width: 100%;
}

#no-style-button {
  background-color: transparent;
  border: none;
}

a.remove-underline:hover {
  text-decoration: none;
}

.login-header {
  text-align: left;
  font-weight: 400;
}

.received {
  font-size: 12px;
  color: #69af23;
  font-style: italic;
}

.rejected {
  font-size: 12px;
  color: red;
  font-style: italic;
}

.defaultPayment {
  font-size: 12px;
  font-style: italic;
}

#languageSelect {
  color: #fff;
  text-decoration: none;
}

#languageSelectIcon {
  padding-bottom: 1rem;
}

#login-dropdown {
  width: 10rem;
  margin-top: 1rem;
  right: 10px;
}

#language-dropdown {
  width: auto;
  margin-top: 1rem;
}

#lang {
  margin-left: 0.5rem;
}

.secondary-header {
  text-align: center;
}

#less-padding {
  padding-top: 1rem;
  margin-bottom: 0;
}

#no-padding {
  padding: 0;
  margin: 0;
}

.small-stuff {
  font-size: 0.8rem;
}

.radio-label {
  margin-bottom: 0;
}

.radio-label-small {
  font-size: 0.8rem;
  color: #757575;
}

.dot-blue {
  height: 15px;
  width: 15px;
  background-color: #0069a7;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.dot-light-blue {
  height: 15px;
  width: 15px;
  background-color: #0b91e1;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.dot-purple {
  height: 15px;
  width: 15px;
  background-color: #552382;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.dot-green {
  height: 15px;
  width: 15px;
  background-color: #92dd52;
  border-radius: 50%;
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;
}

.mini-title {
  font-size: 0.8rem;
  color: #757575;
  padding-bottom: 0;
  margin-bottom: 0;
}

.flex-container {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
}

.flex-item {
  display: flex;
  text-align: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

#total {
  font-size: 18px;
  font-weight: bold;
}

#bold {
  font-weight: bold;
}

#extra {
  font-size: 0.9rem;
  font-style: italic;
  text-align: center;
}

.inner {
  padding-left: 5rem;
}

.edit {
  text-align: right;
  padding-right: 0.5rem;
}

#no-top-padding {
  padding-top: 0.38rem;
  padding-bottom: 0.5rem;
}

#no-border {
  border: none;
}

.big-card {
  background-color: #eff3f6;
  background-size: cover;
  height: auto;
  padding-bottom: 100px;
}

#big-label {
  font-size: 1rem;
}

#copyright {
  font-size: 0.75rem;
  justify-content: start;
  color: #757575;
}

#password-reqs {
  font-size: 0.7rem;
  color: #009de9;
  min-width: 150px;
}

.subtitle {
  font-size: 1.1rem;
  font-weight: bold;
  font-style: italic;
}

/* #password-reqs:before {
    content: "";
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #0af;
    display: block;
    float: left;
    margin-right: 5px;
    margin-top: 3px; } */

.terms {
  text-align: center;
  margin-top: 50px;
  color: #c9c9c9;
}

.terms-link {
  color: #c9c9c9;
}

.terms-link:hover {
  color: #c9c9c9;
  text-decoration: underline;
}

.email-link {
  color: #212121;
}

.email-link:hover {
  color: #727272;
  text-decoration: underline;
}

#padded-link {
  margin-top: 20px;
}

#modal-center {
  align-items: center;
  padding: 10px;
}

#modal-left {
  padding-top: 15px;
  padding-bottom: 15px;
}

#modal-footer {
  padding: 20px;
}

.md-72 {
  font-size: 6rem;
}

.circle-alert--success {
  margin-top: 2rem;
  background-color: #029f56;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  align-items: center;
}

.hoverable {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}

.hoverable:hover {
  box-shadow: 0 8px 15px 3px rgba(0, 0, 0, 0.18);
}

#help {
  background-color: #eff3f6;
  background-size: cover;
  height: 500px;
}

.sidebar {
  color: #757575;
  background: #ffffff;
  width: 250px;
  max-width: 250px;
  height: 100%;
  float: left;
  z-index: 1000;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: margin 1s;
  transition: margin 1s;
  flex: 1;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}

#navbar-toggle {
  cursor: pointer;
}

#toggleView {
  margin-left: 44px;
}

#vert-nav-icons {
  float: right;
}

.sidebar-nav {
  display: block;
  float: left;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

#navbar-toggle:hover {
  color: #fff;
}

.sidebar-nav li a {
  padding-left: 20px;
  font-size: 16px;
  text-decoration: none;
  color: #757575;
  float: left;
  text-decoration: none;
  width: 100%;
  height: 70px;
  line-height: 25px;
  padding: 20px;
  vertical-align: center;
}

.sidebar-nav li a:hover {
  background: #bdbdbd;
  color: #fff;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

#submenu {
  padding-left: 40px;
}

#submenu a {
  text-decoration: none;
  color: #757575;
}

#submenu a:hover {
  background: #eeeeee;
  color: #fff;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

#footer {
  padding-top: 1rem;
  padding-bottom: 0;
  background-color: white;
}

#footer-title-margin {
  margin-bottom: 0.8rem;
}

#footer-list-items {
  line-height: 0.8rem;
}

.captcha {
  background-color: #f9f9f9;
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  color: #4c4a4b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.text {
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 1em;
}

.spinner {
  position: relative;
  width: 2em;
  height: 2em;
  display: flex;
  margin: 2em 1em;
  align-items: center;
  justify-content: center;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  align-self: flex-end;
  margin: 0.5em 1em;
}

.logo img {
  height: 2em;
  width: 2em;
}

.logo p {
  color: #9d9ba7;
  margin: 0;
  font-size: 1em;
  font-weight: 700;
  margin: 0.4em 0 0.2em 0;
}

.logo small {
  color: #9d9ba7;
  margin: 0;
  font-size: 0.8em;
}

@keyframes spin {
  10% {
    width: 0;
    height: 0;
    border-width: 6px;
  }
  30% {
    width: 0;
    height: 0;
    border-radius: 50%;
    border-width: 1em;
    transform: rotate(0deg);
    border-color: #c7daf5;
  }
  50% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  70% {
    border-width: 4px;
    border-color: #c7daf5;
    border-right-color: #5998ef;
  }
  90% {
    border-width: 4px;
  }
  100% {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    transform: rotate(720deg);
    border-color: transparent;
  }
}

#detail-view-bg {
  background-color: #f5f5f5;
}

#detail-view-bg-padding {
  padding-bottom: 3rem;
  background-color: #f5f5f5;
}

#padded-icon {
  padding-bottom: 3px;
}

#smaller-font {
  font-size: 0.875rem;
}

input.nj-form-control::placeholder {
  font-size: 0.875rem;
}

/*# bulk options */
.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  cursor: pointer;
}

.dd-input {
  display: none;
}

.dd-menu {
  position: absolute;
  top: 100%;
  right: 30%;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: left;
  padding: 0;
  margin-top: -15px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  list-style-type: none;
  z-index: 1000;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
}

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.inline-dropdown-menu {
  position: relative;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

#ellipses {
  font-size: xx-large;
  font-weight: bolder;
  flood-color: dimgrey;
  padding-left: 1rem;
  padding-right: 1rem;
}

#page-size {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark-color(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-appearance: menulist;
  box-sizing: border-box;
  align-items: center;
  white-space: pre;
  -webkit-rtl-ordering: logical;
  background-color: rgb(248, 248, 248);
  cursor: default;
  margin: 0em;
  font: 400 11px system-ui;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(166, 166, 166);
  border-image: initial;
}

#circle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  text-align: center;
  justify-content: middle;
}

#triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #cc0033;
}

#box {
  background-color: white;
  padding: 1rem;
  width: 20%;
  text-align: center;
  margin: 0 auto;
  border: 2px solid #e0e0e0;
}

#box-6 {
  width: 45%;
  margin: 0 auto;
}

#box-7 {
  background-color: white;
  width: 30%;
  height: 100%;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  margin: 0 auto;
  border: 2px solid #e0e0e0;
}

#box-2 {
  background-color: white;
  width: 30%;
  height: 100%;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  text-align: center;
  margin: 0 auto;
  border: 2px solid #e0e0e0;
}

#box-5 {
  border: 2px solid #e0e0e0;
  width: 30%;
  text-align: center;
  margin: 0 auto;
  background-color: white;
  line-height: 2rem;
}

#box-3 {
  height: 100%;
  border-left: 2px solid #e0e0e0;
}

#box-4 {
  border: 1px solid #009de9;
  color: #009de9;
  border-radius: 6px;
  background-color: rgba(0, 156, 235, 0.05);
  width: 30%;
  text-align: center;
  margin: 0 auto;
}

#box-text {
  font-size: 0.8rem;
  text-align: center;
}

/* tabs in Summary of Charges  */
.warpper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.tab {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0px 2px;
  /* background:#000; */
  display: inline-block;
  float: right;
  /* color:#fff; */
  /* border-radius:3px 3px 0px 0px; */
  /* box-shadow: 0 0.5rem 0.8rem #00000080; */
}
.panels {
  /* background:#fffffff6; */
  /* box-shadow: 0 2rem 2rem #00000080; */
  min-height: 200px;
  width: 100%;
  /* max-width:500px; */
  border-radius: 3px;
  overflow: hidden;
  padding: 20px;
}
.panel {
  display: none;
  animation: fadein 0.8s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.panel-title {
  font-size: 1.5em;
  font-weight: bold;
}
.radio {
  display: none;
}
#one:checked ~ .panels #one-panel,
#two:checked ~ .panels #two-panel,
#three:checked ~ .panels #three-panel {
  display: block;
}
#one:checked ~ .tabs #one-tab,
#two:checked ~ .tabs #two-tab,
#three:checked ~ .tabs #three-tab {
  /* background:#fffffff6; */
  /* color:#000; */
  border-bottom: 3px solid #0af;
}

/* tooltips */

.tt-wrapper {
  /* text-transform: uppercase; */
  /* color: #555; */
  cursor: help;
  /* margin: 100px 75px 10px 75px; */
  /* padding: 15px 20px; */
  position: relative;
  text-align: center;
  width: auto;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.tt-wrapper .tooltip {
  background: #000;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -30px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  width: auto;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* tooltips */

.tt-wrapper {
  /* text-transform: uppercase; */
  /* color: #555; */
  cursor: help;
  /* margin: 100px 75px 10px 75px; */
  /* padding: 15px 20px; */
  position: relative;
  text-align: center;
  width: auto;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

.tt-wrapper .tooltip {
  background: #000;
  bottom: 100%;
  color: #fff;
  display: block;
  border-radius: 5px;
  left: -85px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  max-width: 300px;
  min-width: 200px;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.tt-wrapper .tooltip:before {
  bottom: -20px;
  content: ' ';
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* CSS Triangles  */
.tt-wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #000 10px;
  bottom: -10px;
  content: ' ';
  height: 0;
  left: 35%;
  margin-left: -13px;
  position: absolute;
  width: 0;
}

.tt-wrapper:hover .tooltip {
  opacity: 0.8;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
}

/* IE can just show/hide with no transition */
.lte8 .tt-wrapper .tooltip {
  display: none;
}

.lte8 .tt-wrapper:hover .tooltip {
  display: block;
}

/* overlapping avatars */

.member-overlap-item {
  margin-left: -28px;
}

.secondary-owner {
  background: #005288;
}

.fortebtn::before {
  font-family: 'Material Icons';
  content: '\e147';
  vertical-align: -3px;
}

/* Help Center */

.header-image {
  background-size: cover;
  height: 500px;
  background-image: url('../assets/brand/HelpCenterHeroImage.jpg');
  opacity: 0.5;
}

.help-overlay {
  content: '';
  background: rgba(0, 0, 0, 0.7);
}

.help-overlay::after {
  content: 'How can we Help?';
  position: absolute;
  top: 350px;
  left: 150px;
  font-size: 3rem;
  box-shadow: black;
  color: white;
}

/* ISO landing page styles */

.landing {
  min-width: 445px;
}

.smlr-crd {
  justify-content: space-between;
  color: #212121;
  background-color: white;
  margin-bottom: 7rem;
}

@media (max-width: 1268px) {
  #landing {
    max-width: 300px;
  }

  .crd {
    font-size: 0.75rem;
  }

  .smlr-crd {
    margin-bottom: 5rem;
  }

  .landing1 {
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .smlr-crd {
    margin-bottom: 0;
  }
  .landing1 {
    margin-bottom: 0;
  }
}

#lowered {
  margin-top: 0.42rem;
}

#left-marg {
  margin-left: 30px;
}

#zero-padng {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 3px;
}

#search-boxes {
  height: 80px;
}

#search-box-align {
  margin-top: 10px;
}

#search-box-align-2 {
  margin-top: 18px;
}

#padding-align {
  padding-top: 19px;
}

#excel {
  color: #0af;
  vertical-align: -1px;
  margin-left: 10px;
  cursor: default;
}

.excel {
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 5px;
}

a#white {
  color: white;
}

a#white:hover {
  opacity: 0.8;
}

#email-address {
  text-transform: lowercase;
  font-size: 0.85rem;
}

.contained-alert {
  max-width: 50%;
  margin: 0 auto;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.img-alert-align {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.875rem;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.bg-blue-engie {
  background-color: #0af !important;
}

a.bg-blue-engie:focus,
a.bg-blue-engie:hover,
button.bg-blue-engie:focus,
button.bg-blue-engie:hover {
  background-color: #0088cc !important;
}

.text-blue-engie {
  color: #0af !important;
}

a.text-blue-engie:hover,
a.text-blue-engie:focus {
  color: #0088cc !important;
}
/* END: Additional Styles */

/* BEGIN: Additional Responsive Styles */
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}

@media (max-width: 768px) {
  .sign-in {
    font-size: 1rem;
  }

  .nj-label-floating {
    font-size: 0.6rem;
  }

  #password-reqs {
    padding: 0;
    font-size: 0.6rem;
  }

  .secondary-header {
    font-size: 1rem;
  }
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  bottom: 0.5em;
}
/* END: Additional Responsive Styles */

/* BEGIN: Responsive Styles for Recaptcha */
@media screen and (max-width: 500px) {
  .captcha {
    flex-direction: column;
  }

  .text {
    margin: 0.5em !important;
    text-align: center;
  }

  .logo {
    align-self: center !important;
  }

  .spinner {
    margin: 2em 0.5em 0.5em 0.5em !important;
  }
}
/* END: Responsive Styles for Recaptcha */

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.justify-content-start {
  justify-content: flex-start !important;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 576px) {
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.review-modal-header {
  font-weight: bold;
  text-align: right;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}
