/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Lato:300,400,700,900&display=swap');
@import '~ag-grid-enterprise/dist/styles/ag-grid.css';
@import '~ag-grid-enterprise/dist/styles/ag-theme-balham.css';
@import '~ag-grid-enterprise/dist/styles/ag-theme-material.css';
@import url('./styles/custom-engie-design.css');
@import url('./styles/custom-app.css');

.article-button {
  border: 1px solid;
  border-radius: 10px;
  padding: 3px 5px;
}

.ag-theme-balham {
  font-family: 'Lato', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ag-theme-balham .ag-header {
  font-family: 'Lato', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto',
    'Helvetica Neue', 'Arial', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.ag-paging-panel {
  justify-content: center;
}

.ag-unselectable {
  justify-content: center;
}

.ag-header-cell-label .ag-header-cell-text {
  white-space: normal !important;
  overflow: visible;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
  line-height: 1rem;
}

.ag-theme-balham .ag-cell-value {
  vertical-align: middle;
}

.grid-cell-align {
  text-align: left;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.field-icon {
  float: right;
  left: -15px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}

.field-icon {
  float: right;
  left: -15px;
  margin-top: -25px;
  position: relative;
  z-index: 2;
}

.ag-cell-wrapper.ag-row-group {
  display: flex;
  align-items: center;
}
html,
body {
  height: 100%;
}

.no-style-link {
  color: inherit;
  text-decoration: none;
}

.no-style-link:hover {
  color: inherit;
}

/* left padding applied to component pages outermost div 
to account for vertical navbar offset (72px for navbar width + 15px 
for container class */
.navbar-pl {
  padding-left: 87px;
}
