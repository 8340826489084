/* Partial and customized copy of Engie Fluid Design styles */
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

body {
  margin: 0;
  color: #212529;
  font-weight: 400;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
}

.nj-alert {
  border-radius: 0.25rem;
}

.nj-avatar {
  width: 3.5rem;
  height: 3.5rem;
}

.nj-avatar__picture {
  background: rgba(0, 157, 233, 0.6)
    url('data:image/svg+xml;base64,PHN2ZyBpZD0iYXZhdGFyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3OCA4NC4xMyI+ICA8dGl0bGU+QXZhdGFyPC90aXRsZT4gIDxwYXRoICAgIGQ9Ik0zOSw4NC4xMkE0Ny40NSw0Ny40NSwwLDAsMSwwLDY0LjE5Qy4xOSw1MS44NywyNiw0NS4xMiwzOSw0NS4xMnMzOC44MSw2Ljc1LDM5LDE5LjA3QTQ3LjQ1LDQ3LjQ1LDAsMCwxLDM5LDg0LjEyWk0zOSwwQTE5LjYzLDE5LjYzLDAsMSwxLDE5LjM4LDE5LjYyLDE5LjYsMTkuNiwwLDAsMSwzOSwwWiIgICAgc3R5bGU9ImZpbGw6IzBhZjtmaWxsLXJ1bGU6ZXZlbm9kZDtmaWxsLW9wYWNpdHk6MC42OyIvPjwvc3ZnPg==')
    center bottom no-repeat;
  background-size: 80%;
}

.nj-avatar .nj-badge {
  right: 0;
  bottom: 0;
}

.nj-avatar--sm {
  width: 2.5rem;
  height: 2.5rem;
}

.nj-avatar--sm .nj-badge {
  right: -5px;
}

.nj-avatar--lg .nj-badge {
  right: 5px;
}

.nj-avatar--bordered .nj-avatar__picture {
  box-shadow: 0 0 0 2px #e0e0e0;
}

.nj-avatar[href].nj-avatar--bordered-blue-engie:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #0af;
}

.nj-avatar[href].nj-avatar--bordered-blue-allports:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #0b74c0;
}

.nj-avatar[href].nj-avatar--bordered-blue-corporate:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #009de9;
}

.nj-avatar[href].nj-avatar--bordered-white:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #fff;
}

.nj-avatar[href].nj-avatar--bordered-green-java:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #6ed2b1;
}

.nj-avatar[href].nj-avatar--bordered-orange-crusta:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #f78a31;
}

.nj-avatar[href].nj-avatar--bordered-purple:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #1f106d;
}

.nj-avatar[href].nj-avatar--bordered-red-mandy:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #e74a50;
}

.nj-avatar[href].nj-avatar--bordered-yellow-bright-sun:hover
  .nj-avatar__picture {
  box-shadow: 0 0 0 2px #fbdb3c;
}

.nj-avatar[href].nj-avatar--bordered-red:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #c03;
}

.nj-avatar[href].nj-avatar--bordered-green:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #009934;
}

.nj-avatar[href].nj-avatar--bordered-orange:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #e07602;
}

.nj-avatar[href].nj-avatar--bordered-blue-bigstone:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #0f1e29;
}

.nj-avatar[href].nj-avatar--bordered-blue-rhino:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #2b485c;
}

.nj-avatar[href].nj-avatar--bordered-blue-venice:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #005288;
}

.nj-avatar[href].nj-avatar--bordered-cerise:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #e62b87;
}

.nj-avatar[href].nj-avatar--bordered-green-fun:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #69af23;
}

.nj-avatar[href].nj-avatar--bordered-green-pine:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #007873;
}

.nj-avatar[href].nj-avatar--bordered-green-riogrande:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #becd00;
}

.nj-avatar[href].nj-avatar--bordered-violet-eggplant:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #910f7d;
}

.nj-avatar[href].nj-avatar--bordered-violet:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #552382;
}

.nj-avatar[href].nj-avatar--bordered-yellow:hover .nj-avatar__picture {
  box-shadow: 0 0 0 2px #faca08;
}

.nj-badge--blue-engie {
  color: #fff;
  background-color: #0af;
}

.nj-badge--blue-engie[href]:hover,
.nj-badge--blue-engie[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0088cc;
}

.nj-badge--blue-venice[href]:hover,
.nj-badge--blue-venice[href]:focus {
  background-color: #003355;
}
.nj-card {
  border-radius: 0.25rem;
}

.nj-card__header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nj-card__body {
  flex: 1 1 auto;
}

.nj-card__date {
  margin: 0;
}

.nj-card__number {
  font-size: 2rem;
}

#sm-nj-number {
  font-size: 2rem;
}

.nj-card__img--top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nj-card__img--bottom {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.nj-footer {
  background-color: #fff;
}

.nj-footer__content {
  padding-top: 3rem;
  padding-bottom: 0rem;
}

.nj-footer__title {
  margin-bottom: 0.5rem;
  color: #212121;
  font-weight: 700;
  font-size: 1.125rem;
}

.nj-footer__bottom {
  justify-content: space-between;
  padding-top: 0.75rem;
}

.nj-footer__legals {
  margin: 0;
  padding-bottom: 2rem;
  color: #757575;
  font-weight: 400;
  font-size: 0.75rem;
}

.nj-footer__corp {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  list-style: none;
}

.nj-footer__corp li {
  margin: 0 0 0.5rem 3rem;
  font-weight: 400;
  line-height: 0.75rem;
}

.nj-footer__corp-link {
  color: #757575;
  font-weight: 400;
  font-size: 0.75rem;
  transition: 0.15s color;
}

.nj-form-group {
  position: relative;
  margin-bottom: 1rem;
  padding-top: 2rem;
}

@media screen and (prefers-reduced-motion: reduce) {
  .nj-footer__corp-link {
    transition: none;
  }
}
.nj-footer__corp-link:hover {
  color: #009de9;
  text-decoration: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

a:not(.nj-link):not(.nj-btn):not(.nj-tag) .material-icons.md--white {
  opacity: 0.7;
}

a:not(.nj-link):not(.nj-btn):not(.nj-tag):hover .material-icons.md--white {
  color: #fff;
  opacity: 1;
}

.nj-list-group {
  margin: 1rem 0;
}

.nj-list-group__item.active {
  font-weight: 700;
  border-left-color: #0af;
}

.nj-list-group a.nj-list-group__item:active,
.nj-list-group button.nj-list-group__item:active {
  font-weight: 700;
  border-left-color: #0af;
}

.nj-modal__content {
  border-radius: 0.25rem;
}

.nj-modal__header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nj-modal__footer {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.nj-navbar {
  height: 4rem;
}

.nj-pagination {
  border-radius: 0.25rem;
}

.nj-pagination__link {
  border-radius: 0.375rem;
}

.nj-progress--blue-engie .nj-progress__bar {
  background-color: #0af;
}

.nj-slider input[type='range']::-ms-track {
  background: linear-gradient(#009de9, #009de9) no-repeat #fff;
  border-width: 8px;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.nj-slider input[type='range']::-ms-fill-lower {
  background-color: #e0e0e0;
  border-radius: 1rem;
}

.nj-slider input[type='range']::-ms-fill-upper {
  margin-right: 15px;
}

.nj-switch input:disabled + .nj-switch__slider {
  background: #e0e0e0;
  cursor: default;
}

.nj-switch input:disabled + .nj-switch__slider .nj-switch__slider-left::before,
.nj-switch
  input:disabled
  + .nj-switch__slider
  .nj-switch__slider-right::before {
  background: #9e9e9e;
}

.nj-switch input:disabled + .nj-switch__slider .nj-switch__slider-left {
  color: #e0e0e0;
}

.nj-switch input:disabled + .nj-switch__slider .nj-switch__slider-right {
  color: #9e9e9e;
}

.nj-switch input:disabled:checked + .nj-switch__slider .nj-switch__slider-left {
  color: #9e9e9e;
}

.nj-switch
  input:disabled:checked
  + .nj-switch__slider
  .nj-switch__slider-right {
  color: #e0e0e0;
}

.nj-tag {
  margin-bottom: calc(0.5rem - 2px);
}

a.bg-blue-venice:focus,
a.bg-blue-venice:hover,
button.bg-blue-venice:focus,
button.bg-blue-venice:hover {
  background-color: #003355 !important;
}

.bg-gradient-primary {
  background: linear-gradient(90deg, #0af 0%, #23d2b5 100%);
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

a.text-gray-100:hover,
a.text-gray-100:focus {
  color: gainsboro !important;
}

.text-gradient-primary {
  background-image: linear-gradient(90deg, #0af 0%, #23d2b5 100%);
}

.container {
  min-height: 300px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    min-height: 300px;
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    min-height: 300px;
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    min-height: 300px;
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    min-height: 300px;
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container {
    min-height: 300px;
    max-width: 1320px;
  }
}
@media (min-width: 1600px) {
  .container {
    min-height: 300px;
    max-width: 1440px;
  }
}

.container-wider {
  min-height: 300px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-wider {
    min-height: 300px;
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-wider {
    min-height: 300px;
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-wider {
    min-height: 300px;
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-wider {
    min-height: 300px;
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-wider {
    min-height: 300px;
    max-width: 1320px;
  }
}
@media (min-width: 1600px) {
  .container-wider {
    min-height: 300px;
    max-width: 1440px;
  }
}
@media (min-width: 1792px) {
  .container-wider {
    min-height: 300px;
    max-width: 1560px;
  }
}
@media (min-width: 1920px) {
  .container-wider {
    min-height: 300px;
    max-width: 1680px;
  }
}
@media (min-width: 2048px) {
  .container-wider {
    min-height: 300px;
    max-width: 1800px;
  }
}
@media (min-width: 2304px) {
  .container-wider {
    min-height: 300px;
    max-width: 2040px;
  }
}
@media (min-width: 2560px) {
  .container-wider {
    min-height: 300px;
    max-width: 2280px;
  }
}

.container-fluid {
  min-height: 300px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-1-5 {
    flex: 0 0 13%;
    max-width: 13%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}

/* calculate scrollbar width */
div.fixed-table-scroll-inner {
  width: 100%;
  height: 200px;
}

div.fixed-table-scroll-outer {
  top: 0;
  left: 0;
  visibility: hidden;
  width: 200px;
  height: 150px;
  overflow: hidden;
}

@keyframes LOADING {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

hr.hr-title {
  width: 40px;
  height: 6px;
  margin: 1rem 0;
  text-align: left;
  background-color: #212121;
  border: 0;
  border-radius: 1rem;
}

hr.hr-title--primary {
  background-color: #009de9;
}

hr.hr-title--white {
  background-color: #fff;
}

.material-icons.md--blue-engie {
  color: #0af;
}

.nj-icon--circle {
  color: #fff;
}

.nj-icon--circle::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 6rem;
  height: 6rem;
  background-color: #009de9;
  border-radius: 50%;
  content: '';
}

.nj-icon--border::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 6rem;
  height: 6rem;
  border: 1px solid #212121;
  border-radius: 50%;
  content: '';
}

.nj-icon--blue-engie {
  color: #0af;
}

.nj-icon--blue-engie.nj-icon--circle {
  color: #fff;
}

.nj-icon--blue-engie.nj-icon--circle::after {
  background-color: #0af;
}

.nj-icon--blue-engie.nj-icon--border::after {
  border-color: #0af;
}

.nj-icon--blue-allports {
  color: #0b74c0;
}

.nj-icon--blue-allports.nj-icon--circle {
  color: #fff;
}

.nj-icon--blue-allports.nj-icon--circle::after {
  background-color: #0b74c0;
}

.nj-icon--blue-allports.nj-icon--border::after {
  border-color: #0b74c0;
}

.nj-icon--blue-corporate.nj-icon--circle {
  color: #fff;
}

.nj-icon--blue-corporate.nj-icon--circle::after {
  background-color: #009de9;
}

.nj-icon--blue-corporate.nj-icon--border::after {
  border-color: #009de9;
}

.nj-icon--white.nj-icon--circle {
  color: #fff;
  color: #009de9;
}

.nj-icon--white.nj-icon--circle::after {
  background-color: #fff;
}

.nj-icon--white.nj-icon--border::after {
  border-color: #fff;
}

.nj-icon--green-java {
  color: #6ed2b1;
}

.nj-icon--green-java.nj-icon--circle {
  color: #fff;
}

.nj-icon--green-java.nj-icon--circle::after {
  background-color: #6ed2b1;
}

.nj-icon--green-java.nj-icon--border::after {
  border-color: #6ed2b1;
}

.nj-icon--orange-crusta {
  color: #f78a31;
}

.nj-icon--orange-crusta.nj-icon--circle {
  color: #fff;
}

.nj-icon--orange-crusta.nj-icon--circle::after {
  background-color: #f78a31;
}

.nj-icon--orange-crusta.nj-icon--border::after {
  border-color: #f78a31;
}

.nj-icon--purple {
  color: #1f106d;
}

.nj-icon--purple.nj-icon--circle {
  color: #fff;
}

.nj-icon--purple.nj-icon--circle::after {
  background-color: #1f106d;
}

.nj-icon--purple.nj-icon--border::after {
  border-color: #1f106d;
}

.nj-icon--red-mandy {
  color: #e74a50;
}

.nj-icon--red-mandy.nj-icon--circle {
  color: #fff;
}

.nj-icon--red-mandy.nj-icon--circle::after {
  background-color: #e74a50;
}

.nj-icon--red-mandy.nj-icon--border::after {
  border-color: #e74a50;
}

.nj-icon--yellow-bright-sun {
  color: #fbdb3c;
}

.nj-icon--yellow-bright-sun.nj-icon--circle {
  color: #fff;
}

.nj-icon--yellow-bright-sun.nj-icon--circle::after {
  background-color: #fbdb3c;
}

.nj-icon--yellow-bright-sun.nj-icon--border::after {
  border-color: #fbdb3c;
}

.nj-icon--red {
  color: #c03;
}

.nj-icon--red.nj-icon--circle {
  color: #fff;
}

.nj-icon--red.nj-icon--circle::after {
  background-color: #c03;
}

.nj-icon--red.nj-icon--border::after {
  border-color: #c03;
}

.nj-icon--green {
  color: #009934;
}

.nj-icon--green.nj-icon--circle {
  color: #fff;
}

.nj-icon--green.nj-icon--circle::after {
  background-color: #009934;
}

.nj-icon--green.nj-icon--border::after {
  border-color: #009934;
}

.nj-icon--orange {
  color: #e07602;
}

.nj-icon--orange.nj-icon--circle {
  color: #fff;
}

.nj-icon--orange.nj-icon--circle::after {
  background-color: #e07602;
}

.nj-icon--orange.nj-icon--border::after {
  border-color: #e07602;
}

.nj-icon--blue-bigstone {
  color: #0f1e29;
}

.nj-icon--blue-bigstone.nj-icon--circle {
  color: #fff;
}

.nj-icon--blue-bigstone.nj-icon--circle::after {
  background-color: #0f1e29;
}

.nj-icon--blue-bigstone.nj-icon--border::after {
  border-color: #0f1e29;
}

.nj-icon--blue-rhino {
  color: #2b485c;
}

.nj-icon--blue-rhino.nj-icon--circle {
  color: #fff;
}

.nj-icon--blue-rhino.nj-icon--circle::after {
  background-color: #2b485c;
}

.nj-icon--blue-rhino.nj-icon--border::after {
  border-color: #2b485c;
}

.nj-icon--blue-venice {
  color: #005288;
}

.nj-icon--blue-venice.nj-icon--circle {
  color: #fff;
}

.nj-icon--blue-venice.nj-icon--circle::after {
  background-color: #005288;
}

.nj-icon--blue-venice.nj-icon--border::after {
  border-color: #005288;
}

.nj-icon--cerise {
  color: #e62b87;
}

.nj-icon--cerise.nj-icon--circle {
  color: #fff;
}

.nj-icon--cerise.nj-icon--circle::after {
  background-color: #e62b87;
}

.nj-icon--cerise.nj-icon--border::after {
  border-color: #e62b87;
}

.nj-icon--green-fun {
  color: #69af23;
}

.nj-icon--green-fun.nj-icon--circle {
  color: #fff;
}

.nj-icon--green-fun.nj-icon--circle::after {
  background-color: #69af23;
}

.nj-icon--green-fun.nj-icon--border::after {
  border-color: #69af23;
}

.nj-icon--green-pine {
  color: #007873;
}

.nj-icon--green-pine.nj-icon--circle {
  color: #fff;
}

.nj-icon--green-pine.nj-icon--circle::after {
  background-color: #007873;
}

.nj-icon--green-pine.nj-icon--border::after {
  border-color: #007873;
}

.nj-icon--green-riogrande {
  color: #becd00;
}

.nj-icon--green-riogrande.nj-icon--circle {
  color: #fff;
}

.nj-icon--green-riogrande.nj-icon--circle::after {
  background-color: #becd00;
}

.nj-icon--green-riogrande.nj-icon--border::after {
  border-color: #becd00;
}

.nj-icon--violet-eggplant {
  color: #910f7d;
}

.nj-icon--violet-eggplant.nj-icon--circle {
  color: #fff;
}

.nj-icon--violet-eggplant.nj-icon--circle::after {
  background-color: #910f7d;
}

.nj-icon--violet-eggplant.nj-icon--border::after {
  border-color: #910f7d;
}

.nj-icon--violet {
  color: #552382;
}

.nj-icon--violet.nj-icon--circle {
  color: #fff;
}

.nj-icon--violet.nj-icon--circle::after {
  background-color: #552382;
}

.nj-icon--violet.nj-icon--border::after {
  border-color: #552382;
}

.nj-icon--yellow {
  color: #faca08;
}

.nj-icon--yellow.nj-icon--circle {
  color: #fff;
}

.nj-icon--yellow.nj-icon--circle::after {
  background-color: #faca08;
}

.nj-icon--yellow.nj-icon--border::after {
  border-color: #faca08;
}

.nj-switch--light .nj-switch__slider-left::before,
.nj-switch--light .nj-switch__slider-right::before {
  background: #fff;
}

.nj-switch--light .nj-switch__slider-right {
  color: #fff;
}

.nj-switch--light input:checked + .nj-switch__slider .nj-switch__slider-left {
  color: #fff;
}

.nj-switch--light .nj-switch__slider {
  background: #bdbdbd;
}

.nj-switch--light .nj-switch__slider-left {
  color: #bdbdbd;
}

.nj-switch--light input:checked + .nj-switch__slider .nj-switch__slider-right {
  color: #bdbdbd;
}

.nj-tag--blue-engie:not(.disabled) .nj-tag__icon:hover {
  color: #fff;
  background-color: #0096e0;
}

.nj-tag--blue-engie:not(.disabled) .nj-tag__icon:active {
  background-color: #007ebd;
}

.nj-tag--blue-engie:not(.disabled):hover {
  color: #fff;
  background-color: #0af;
}

.nj-tag--blue-engie:not(.disabled)[href]:active {
  color: #fff;
  background-color: #007ebd;
  border-color: #007ebd;
}

.nj-tag--blue-allports {
  border: 1px solid #0b74c0;
}

.nj-tag--blue-engie {
  border: 1px solid #0af;
}

.nj-tag--blue-engie:hover {
  text-decoration: none;
}

.nj-tag--blue-engie:not(.disabled) .nj-tag__icon {
  cursor: pointer;
}

.nj-tag--blue-allports:hover {
  text-decoration: none;
}

.nj-tag--blue-allports:not(.disabled) .nj-tag__icon {
  cursor: pointer;
}

.nj-tag--blue-allports:not(.disabled) .nj-tag__icon:hover {
  color: #fff;
  background-color: #0a66a9;
}

.nj-tag--blue-allports:not(.disabled) .nj-tag__icon:active {
  background-color: #08568e;
}

.nj-tag--blue-allports:not(.disabled):hover {
  color: #fff;
  background-color: #0b74c0;
}

.nj-tag--blue-corporate:not(.disabled):hover {
  color: #fff;
  background-color: #009de9;
}

.nj-tag--blue-corporate:not(.disabled)[href]:active {
  color: #fff;
  background-color: #0074ac;
  border-color: #0074ac;
}

.nj-tag--white:not(.disabled):hover {
  color: #212121;
  background-color: #fff;
}

.nj-tag--white:not(.disabled)[href]:active {
  color: #212121;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.nj-tag--green-java:not(.disabled):hover {
  color: #fff;
  background-color: #6ed2b1;
}

.nj-tag--green-java:not(.disabled)[href]:active {
  color: #fff;
  background-color: #519b83;
  border-color: #519b83;
}

.nj-tag--orange-crusta:not(.disabled):hover {
  color: #fff;
  background-color: #f78a31;
}

.nj-tag--orange-crusta:not(.disabled)[href]:active {
  color: #fff;
  background-color: #b76624;
  border-color: #b76624;
}

.nj-tag--purple:not(.disabled):hover {
  color: #fff;
  background-color: #1f106d;
}

.nj-tag--purple:not(.disabled)[href]:active {
  color: #fff;
  background-color: #170c51;
  border-color: #170c51;
}

.nj-tag--red-mandy:not(.disabled):hover {
  color: #fff;
  background-color: #e74a50;
}

.nj-tag--red-mandy:not(.disabled)[href]:active {
  color: #fff;
  background-color: #ab373b;
  border-color: #ab373b;
}

.nj-tag--yellow-bright-sun:not(.disabled):hover {
  color: #212121;
  background-color: #fbdb3c;
}

.nj-tag--yellow-bright-sun:not(.disabled)[href]:active {
  color: #212121;
  background-color: #baa22c;
  border-color: #baa22c;
}

.nj-tag--red:not(.disabled):hover {
  color: #fff;
  background-color: #c03;
}

.nj-tag--red:not(.disabled)[href]:active {
  color: #fff;
  background-color: #970026;
  border-color: #970026;
}

.nj-tag--green:not(.disabled):hover {
  color: #fff;
  background-color: #009934;
}

.nj-tag--green:not(.disabled)[href]:active {
  color: #fff;
  background-color: #007126;
  border-color: #007126;
}

.nj-tag--orange:not(.disabled):hover {
  color: #fff;
  background-color: #e07602;
}

.nj-tag--orange:not(.disabled)[href]:active {
  color: #fff;
  background-color: #a65701;
  border-color: #a65701;
}

.nj-tag--blue-bigstone:not(.disabled):hover {
  color: #fff;
  background-color: #0f1e29;
}

.nj-tag--blue-bigstone:not(.disabled)[href]:active {
  color: #fff;
  background-color: #0b161e;
  border-color: #0b161e;
}

.nj-tag--blue-rhino:not(.disabled):hover {
  color: #fff;
  background-color: #2b485c;
}

.nj-tag--blue-rhino:not(.disabled)[href]:active {
  color: #fff;
  background-color: #203544;
  border-color: #203544;
}

.nj-tag--blue-venice:not(.disabled):hover {
  color: #fff;
  background-color: #005288;
}

.nj-tag--blue-venice:not(.disabled)[href]:active {
  color: #fff;
  background-color: #003d65;
  border-color: #003d65;
}

.nj-tag--cerise:not(.disabled):hover {
  color: #fff;
  background-color: #e62b87;
}

.nj-tag--cerise:not(.disabled)[href]:active {
  color: #fff;
  background-color: #aa2064;
  border-color: #aa2064;
}

.nj-tag--green-fun:not(.disabled):hover {
  color: #fff;
  background-color: #69af23;
}

.nj-tag--green-fun:not(.disabled)[href]:active {
  color: #fff;
  background-color: #4e821a;
  border-color: #4e821a;
}

.nj-tag--green-pine:not(.disabled):hover {
  color: #fff;
  background-color: #007873;
}

.nj-tag--green-pine:not(.disabled)[href]:active {
  color: #fff;
  background-color: #005955;
  border-color: #005955;
}

.nj-tag--green-riogrande:not(.disabled):hover {
  color: #fff;
  background-color: #becd00;
}

.nj-tag--green-riogrande:not(.disabled)[href]:active {
  color: #fff;
  background-color: #8d9800;
  border-color: #8d9800;
}

.nj-tag--violet-eggplant:not(.disabled):hover {
  color: #fff;
  background-color: #910f7d;
}

.nj-tag--violet-eggplant:not(.disabled)[href]:active {
  color: #fff;
  background-color: #6b0b5d;
  border-color: #6b0b5d;
}

.nj-tag--violet:not(.disabled):hover {
  color: #fff;
  background-color: #552382;
}

.nj-tag--violet:not(.disabled)[href]:active {
  color: #fff;
  background-color: #3f1a60;
  border-color: #3f1a60;
}

.nj-tag--yellow:not(.disabled):hover {
  color: #212121;
  background-color: #faca08;
}

.nj-tag--yellow:not(.disabled)[href]:active {
  color: #212121;
  background-color: #b99506;
  border-color: #b99506;
}

.p-4 {
  padding: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.h3,
h3 {
  font-weight: 500;
  font-size: 1.75rem;
}

.h4,
h4 {
  font-weight: 500;
  font-size: 1.5rem;
}

.h5,
h5 {
  font-weight: 500;
  font-size: 1.25rem;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
